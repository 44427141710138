import "bootstrap/dist/js/bootstrap.min"
import { clearLocalStores } from "./localStorage"

function addEventHoverSubMenu() {
  $(".show-sub-menu-1").hover(
    function () {
      $(".sub-menu-1").removeClass("active")
      $(this).find(".sub-menu-1").addClass("active")
    },
    function () {
      $(this).find(".sub-menu-1").removeClass("active")
    },
  )

  $(".show-sub-menu-2").hover(
    function () {
      $(".show-sub-menu-2.sub-menu").removeClass("active")
      $(this).find(".sub-menu").addClass("active")
    },
    function () {
      $(this).find(".sub-menu").removeClass("active")
    },
  )
}

function addEventHoverMenuBar() {
  $(".navigation.main-side-menu").hover(function () {
    $(this).toggleClass("active")
  })
}

function removeEventHoverMenuBar() {
  $(".navigation.main-side-menu").unbind("mouseenter mouseleave")
}

function toggleMenu() {
  $("#icon-hamburger").on("click", function (e) {
    e.preventDefault()
    const statusMenu = $(this).attr("data-status-menu")
    if (statusMenu === "hide") {
      $(".navigation.main-side-menu").addClass("active")
      removeEventHoverMenuBar()
      $(this).attr("data-status-menu", "show")
    } else {
      addEventHoverMenuBar()
      $(this).attr("data-status-menu", "hide")
      $(".sub-menu").removeClass("active")
    }
  })
}

function clickOutside() {
  $(document).on("click", (e) => {
    const container = $(".main-side-menu")

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      container.find(".sub-menu").removeClass("active")
    }
  })
}

function handleLogout() {
  $(".confirm-logout").on("click", (e) => {
    e.preventDefault()
    const formChange = $("form.js-exit-form")
    if (!formChange.data("changed")) {
      $("#modal-confirm-logout").modal("show")
    } else {
      $("#modal-warning-logout").modal("show")
    }
  })

  $("#confirm-warning-logout").on("click", (e) => {
    e.preventDefault()
    $("#modal-confirm-logout").modal("show")
  })
}

function showProfile() {
  $("#show-profile").on("click", (e) => {
    e.preventDefault()
    $("#modal-show-profile").modal("show")
  })
}

function showNoticeFromSystem() {
  $("#notice-from-system").on("click", (e) => {
    e.preventDefault()
    $("#modal-notice-from-system").modal("show")
  })
}

function logOutSystem() {
  $(".btn-logout").on("click", () => {
    clearLocalStores()
  })
}

function logInSystem() {
  $(".btn-login").on("click", () => {
    clearLocalStores()
  })
}

$(document).ready(() => {
  toggleMenu()
  clickOutside()
  handleLogout()
  showProfile()
  showNoticeFromSystem()
  logOutSystem()
  addEventHoverMenuBar()
  addEventHoverSubMenu()
  logInSystem()
})
