function FlashTimeout() {
  const module = this

  module.setFlashTimeout = function () {
    setTimeout(() => {
      $(".alert-success").remove();
    }, 3000);
  }

  module.init = function () {
    module.setFlashTimeout()
  }
}

$(document).ready(() => {
  const flashTimeout = new FlashTimeout()
  flashTimeout.init()
})
