import "./ajaxSetup"

const TIME_OUT = 7200

class WorkerInterval {
  worker = null;

  constructor(callback, interval) {
    const blob = new Blob([`setInterval(() => postMessage(0), ${interval});`]);
    const workerScript = URL.createObjectURL(blob);
    this.worker = new Worker(workerScript);
    this.worker.onmessage = callback;
  }

  stop() {
    this.worker.terminate();
  }
}

function CheckTimeout() {
  const module = this
  module.refreshIntervalId = null
  module.workerTimer = null
  module.lastDate = null

  const timeOutElement = document.getElementById("timeout")

  const getCookie = (name) => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(";").shift()
    return null
  }

  const startTimer = (duration) => {
    let hours
    let minutes
    let seconds
    module.refreshIntervalId = setInterval(() => {
      const now = new Date();
      const remaining = Math.floor(duration - ((now.getTime() - module.lastDate.getTime()) / 1000));
      hours = parseInt(remaining / 3600, 10)
      minutes = parseInt((remaining % 3600) / 60, 10)
      seconds = parseInt(remaining % 60, 10)

      minutes = minutes < 10 ? `0${minutes}` : minutes
      seconds = seconds < 10 ? `0${seconds}` : seconds

      timeOutElement.textContent = ` ${hours}:${minutes}:${seconds}`

      if (remaining < 0) {
        clearInterval(module.refreshIntervalId);
        window.location.reload();
      }
    }, 1000)
  }

  module.getTimeout = () => {
    $.ajax({
      url: "/api/check_session_timeout",
      type: "GET",
      success(response) {
        if (module.workerTimer !== null) {
          module.workerTimer.stop()
          module.lastDate = new Date();
          module.workerTimer = new WorkerInterval(startTimer(response.ttl), 100);
        }
      },
    })
  }

  module.listenCookieChange = () => {
    let oldRequestAt = getCookie("_last_request_at")
    setInterval(() => {
      const newRequestAt = getCookie("_last_request_at")
      if (!oldRequestAt || newRequestAt - 3 > oldRequestAt) {
        try {
          clearInterval(module.refreshIntervalId)
          module.getTimeout()
        } finally {
          oldRequestAt = newRequestAt
        }
      }
    }, 3000)
  }

  module.init = () => {
    if (!timeOutElement) return

    module.lastDate = new Date();
    module.workerTimer = new WorkerInterval(startTimer(TIME_OUT), 100);
    module.listenCookieChange()
  }
}
$(() => {
  const checkTimeout = new CheckTimeout()
  checkTimeout.init()
})
