// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@fortawesome/fontawesome-free/css/all"
import "../stylesheets/application"
import "../common/base"
import "../common/flashTimeout"
import "../common/checkTimeout"
import "../common/redirectPage"

import $ from "jquery"

// eslint-disable-next-line no-multi-assign
window.$ = window.jQuery = $

Rails.start()
