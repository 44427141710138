function RedirectPage() {
  const module = this
  module.modalRedirect = $("#modal-redirect-page")
  module.confirmRedirect = $("#confirm-redirect-page")
  module.form = $("form.js-exit-form")

  module.windowClose = (event) => {
    (event || window.event).returnValue = null
    return null
  }

  module.confirmMove = (event) => {
    if (module.form.data("changed")) {
      module.modalRedirect.modal("show")
      module.modalRedirect.data("href", event.currentTarget.href)
      event.preventDefault()
      window.onbeforeunload = module.windowClose
    }
    return true
  }

  module.init = function () {
    window.onbeforeunload = null
    module.form.on("change", () => {
      if (!module.form.data("changed")) {
        module.form.data("changed", true)
        window.onbeforeunload = module.windowClose
      }
    })

    if (module.form.length > 0) {
      module.form.on("submit", () => { window.onbeforeunload = null })
      $(".btn-logout").on("click", () => {
        window.onbeforeunload = null
        const href = module.modalRedirect.data("href")
        window.location.href = href
      })
      const e = $('a:not([target="_blank"], [href^="#"], [href^="."], [data-confirm], .js-no-change, .btn-logout)')
      e.on("click", module.confirmMove)
    }

    module.confirmRedirect.on("click", () => {
      window.onbeforeunload = null
      const href = module.modalRedirect.data("href")
      window.location.href = href
    })
  }
}

$(() => {
  const redirectPage = new RedirectPage()
  redirectPage.init()
})
