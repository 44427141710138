import ls from "local-storage"

function setLocalStores(key, msg) {
  ls.set(key, msg)
}
function getLocalStores(key) {
  return ls.get(key)
}
function removeLocalStores(key) {
  return ls.remove(key)
}

function clearLocalStores() {
  return ls.clear()
}

export { setLocalStores, getLocalStores, removeLocalStores, clearLocalStores }
